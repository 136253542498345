body{
  height: 100%;
  background-color: #f4f4f4 !important;
}
.dashboard-header{
  border: 2px solid green;
  color: green;
  width: 80%;
  font-size: 20px;
}
.dashboard-body{
  margin-top: 50px;
}
.break-word-data{
word-break: break-all;
}
td{
  font-size: 12px;
}
.gitlab-username{
  font-size: 12px;
}